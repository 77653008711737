import React from 'react';

const Unauthorized = () => {
  return (
    <div className='global-container'>
      <div className='main-card  d-flex align-items-center justify-content-center'>
      <h1>Unauthorized Access</h1>
     
    </div>
    </div>
  );
};

export default Unauthorized;
